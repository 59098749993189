<template>
  <div class="cover-image">
    <img style="width: 100%" :src="require('@/img/cover.png')" alt="banner" />
  </div>
</template>
<script>

const CoverSection = {
  name: 'CoverSection'
};

export default CoverSection;
</script>
